import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { Subheading } from "../headings"
import FluidContainer from "../fluidContainer"
import { PrimaryButton } from "../buttons"
import ReactMarkdown from "react-markdown"

const NewsTitle = styled.h3`
  font-weight: 500;
  margin-bottom: 0.5rem;
`

const NewsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`
const ImageContainer = styled.div`
  flex-basis: 50%;
  @media (max-width: 768px) {
    display: none;
  }
`

const NewsDate = styled.h4`
  color: #b5232b;
  margin-bottom: 1rem;
`

const NewsList = styled.ul`
  flex-basis: 50%;
  max-width: 40%;
  a {
    text-decoration: none;
  }
  a:nth-of-type(1) {
    li {
      padding-top: 0;
    }
  }
  a:nth-of-type(3) {
    li {
      border-bottom: none;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const NewsContent = styled.p`
  font-weight: 300;
  display: inline-block;
  margin-bottom: 0;
  overflow-x: hidden;
  width: 100%;
  }
`

const NewsSection = styled.li`
  list-style: none;
  font-family: roboto;
  color: white;
  text-decoration: none;
  border-bottom: 1px dashed gray;
  padding: 1rem 0;
`

const Spacer = styled.div`
  padding: 2rem 0;
`

const News = ({ news, img }) => {
  return (
    <Spacer>
      <FluidContainer>
        <NewsContainer>
          <NewsList>
            <Subheading>News</Subheading>
            {news.map((n, i) => (
              <Link key={i} to={`articles/${n.node.sn}`}>
                <NewsSection>
                  <NewsTitle>{n.node.title}</NewsTitle>
                  <NewsDate>[{n.node.createdAt}]</NewsDate>
                  <NewsContent>
                    <ReactMarkdown
                      escapeHtml={false}
                      source={n.node.content.substring(0, 200) + "..."}
                    />
                  </NewsContent>
                </NewsSection>
              </Link>
            ))}
            <Link to={`articles`}>
              <PrimaryButton>Read more</PrimaryButton>
            </Link>
          </NewsList>
          <ImageContainer>
            <Img fluid={img} />
          </ImageContainer>
        </NewsContainer>
      </FluidContainer>
    </Spacer>
  )
}

export default News
