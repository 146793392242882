import React from "react"
import YouTube from "./youtube"
import Instagram from "./instagram"
import styled from "@emotion/styled"
import FluidContainer from "../fluidContainer"
import { FaYoutube, FaInstagram } from "react-icons/fa"

const SocialMediaContainer = styled.div`
  background-color: transparent;
  width: 100%;
  padding-bottom: 2rem;
`

const ButtonContainer = styled.div`
  @media (max-width: 576px) {
    margin-top: 1rem;
    text-align: center;
  }
`

const Button = styled.button`
  border: none;
  padding: 0.2rem 0.7rem;
  border-radius: 6px;
  font-size: 2rem;
  background-color: #003357;
  color: white;
  margin: 0.1rem;
  cursor: pointer;
  filter: ${props => (props.notActive ? "grayscale(100%)" : "grayscale(0%)")};
`

export default class SocialMedia extends React.Component {
  state = {
    youtube: [],
    instagram: [],
    toShow: "youtube",
  }

  updateState = (social, data) => {
    return this.setState({
      [social]: data,
    })
  }

  setSocialMedia = media => {
    return this.setState({
      toShow: media,
    })
  }
  render() {
    const { youtube, instagram, toShow } = this.state
    return (
      <SocialMediaContainer>
        <FluidContainer>
          <ButtonContainer>
            <Button
              notActive={this.state.toShow !== "youtube"}
              onClick={() => this.setSocialMedia("youtube")}
            >
              <FaYoutube />
            </Button>
            <Button
              notActive={this.state.toShow !== "instagram"}
              onClick={() => this.setSocialMedia("instagram")}
            >
              <FaInstagram />
            </Button>
          </ButtonContainer>
          {toShow === "instagram" && (
            <Instagram updateState={this.updateState} data={instagram} />
          )}
          {toShow === "youtube" && (
            <YouTube updateState={this.updateState} data={youtube} />
          )}
        </FluidContainer>
      </SocialMediaContainer>
    )
  }
}
