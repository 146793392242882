import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import backgroundImage from "../../images/rankings-background.png"
import { Subheading } from "../headings"
import { PrimaryButton } from "../buttons"
import FluidContainer from "../fluidContainer"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const LeftContainer = styled.div`
  text-align: center;
  flex-basis: 40%;
  @media (max-width: 768px) {
    flex: 1 0 100%;
  }
`

const EaterRank = styled.div`
  background-color: #b5232b;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.3rem;
  border-radius: 5px;
`

const RankingComponent = styled.div`
  width: 100%;
  background-image: url(${backgroundImage});
  border-top: 16px #3e4951 solid;
  border-bottom: 16px #3e4951 solid;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const Copy = styled.p`
  font-size: 1rem;
  font-family: roboto;
  font-weight: 300;
`

const EatersList = styled.ul`
  display: flex;
  margin-bottom: 0;
  width: 100%;
  flex-basis: 60%;

  @media (max-width: 768px) {
    display: none;
  }
`

const EatersListItem = styled.li`
  margin-bottom: 0;
  max-width: 220px;
  flex-basis: 31%;
  height: 200px;
  font-family: roboto;
  position: relative;
  list-style: none;
  margin: 0 0.2rem;
  overflow: hidden;
`

const BackgroundContainer = styled.div`
  margin-bottom: 0;
  background-image: url(${props => props.background});
  background-position: top;
  background-size: cover;
  color: white;

  max-width: 220px;
  height: 200px;
  @media (max-width: 768px) {
    display: none;
  }
`

const EaterName = styled.p`
  position: absolute;
  bottom: 0;
  background-color: #003357;
  width: 100%;
  text-align: center;
`

const Rankings = ({ eaters }) => {
  return (
    <RankingComponent>
      <FluidContainer style={{ padding: "1rem" }}>
        <Container>
          <LeftContainer>
            <Subheading style={{ marginBottom: "0.5rem" }}>
              EATER RANKINGS
            </Subheading>
            {/* <Copy>Hey here's some rankings</Copy> */}
            <Link to="/eaters">
              <PrimaryButton>View more</PrimaryButton>
            </Link>
          </LeftContainer>
          <EatersList>
            {eaters.map(e => (
              <EatersListItem>
                <Link to={`eaters/${e.node.sn}`}>
                  <BackgroundContainer
                    background={e.node.image && e.node.image.publicURL}
                  >
                    <EaterRank>#{e.node.rank}</EaterRank>
                    <EaterName>
                      {e.node.nameF} {e.node.nameL}
                    </EaterName>
                  </BackgroundContainer>
                </Link>
              </EatersListItem>
            ))}
          </EatersList>
        </Container>
      </FluidContainer>
    </RankingComponent>
  )
}

export default Rankings
