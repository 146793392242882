import React from "react"
import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"
import Banner from "../components/banner"
import { Header1 } from "../components/headings"
import Layout from "../components/layout"
import Events from "../components/Index/events"
import SocialMedia from "../components/Index/socialMedia"
import Rankings from "../components/Index/rankings"
import Records from "../components/Index/records"
import Subscribe from "../components/Index/subscribe"
import News from "../components/Index/news"
import Carousel from "../components/Index/carousel"
import SEO from "../components/seo";
import Modal from "../components/modal"
import { PrimaryButton } from "../components/buttons"
import backgroundImage from "../images/records-background.png";
import sponsorBanner from "../images/draftking-banner.png";

const BackgroundContainer = styled.div`
  background-image: ${props => props.background};
  background-size: cover;
  background-position-y: -500px;
  background-repeat-y: no-repeat;
`

const IndexPage = ({ data }) => {
  return (
    <Layout>      
      <SEO title="Home" keywords={[`eating`, `contests`, `official`]} />
 
<Banner img={data.placeholderImage.childImageSharp.fluid}>
        <Header1>
          <div
            style={{ fontSize: "2rem", lineHeight: "3rem", fontWeight: 300 }}
          >
            The Professional League of
          </div>
          <div>Eating Contests</div>

        </Header1>
        <Link to="/contests">
          <PrimaryButton>View Events</PrimaryButton>
        </Link>
      </Banner>
      <SocialMedia />
      <Events documents={data.allStrapiContests.edges} />
      <Rankings eaters={data.allStrapiProfiles.edges} />
      <BackgroundContainer
        background={`url(${backgroundImage}), linear-gradient(#11202a, #0a1721)`}
      >
        <Records records={data.allStrapiRecords.edges} />
        <News
          news={data.allStrapiArticles.edges}
          img={data.newsImage.childImageSharp.fluid}
        />
      </BackgroundContainer>
      <Carousel logos={data.allStrapiLogos.edges} />
      <Subscribe />
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiArticles(limit: 3, sort: { fields: [createdAt], order: [DESC] }) {
      edges {
        node {
          sn
          id
          title
          createdAt(formatString: "MM/DD/YYYY")
          content
        }
      }
    }
    allStrapiRecords(limit: 11, sort: { fields: [food] }) {
      edges {
        node {
          food
          description
          time
          profile {
            nameL
            nameF
          }
        }
      }
    }
    allStrapiProfiles(
      filter: { rank: { lte: 50 } }
      limit: 3
      sort: { fields: [rank] }
    ) {
      edges {
        node {
          nameF
          nameL
          sn
          rank
          image {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allStrapiContests(
      limit: 50
      sort: { fields: [date], order: [DESC] }
      filter: { hide: { ne: true } }
    ) {
      edges {
        node {
          id
          name
          prize
          discipline
          sn
          date(formatString: "MM/DD/YYYY")
          logo {
            image {
              url
            }
          }
        }
      }
    }
    allStrapiLogos(filter: { showInCarousel: { eq: true } }) {
      edges {
        node {
          link
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "index-banner-2024-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsImage: file(relativePath: { eq: "george.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
