import React from "react"
import styled from "@emotion/styled"
import { PrimaryButton } from "../buttons"
import { Header1, Header2 } from "../headings"
import youtubeImage from "../../images/yt_logo_rgb_dark.png"

const YouTubeContainer = styled.div`
  .copyTextMobile {
    display: none;
  }
  @media (max-width: 768px) {
    .copyTextMobile {
      display: block;
    }
  }
`
const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  #ytplayer {
    width: 100%;
  }
  #ytplayer-mobile {
    display: none;
  }
  .copyTextMobile {
    display: none;
  }
  .copyText {
    padding-left: 1rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .copyText {
      display: none;
    }

    .copyTextMobile {
      display: block;
    }
    iframe {
      width: 100%;
    }
  }
  @media (max-width: 450px) {
    iframe {
      display: none;
    }
    #ytplayer-mobile {
      display: block;
    }
  }
`

const ButtonContainer = styled.div`
  text-align: center;
`
const VideoList = styled.ul`
  margin: 0;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin: 2rem 0;
    #li-4,
    #li-5 {
      display: none;
    }
  }

  @media (max-width: 450px) {
    margin: 0;
    margin-bottom: 1rem;
    flex-direction: column;
  }
`

const VideoListItem = styled.li`
  list-style: none;
  position: relative;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-basis: 50%;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    flex-basis: 100%;
    margin: 1rem auto 0 auto;
  }
`

export default class YouTube extends React.Component {
  renderVideos = videos => {
    return videos.map(({ snippet }, i) => (
      <VideoListItem id={`li-${i}`} key={i}>
        <a
          target="_blank"
          href={`https://youtube.com/watch?v=${snippet.resourceId.videoId}`}
        >
          <img src={snippet.thumbnails.medium.url} />
        </a>
      </VideoListItem>
    ))
  }
  componentDidMount() {
    if (this.props.data.length === 0) {
      return fetch(
        "https://www.googleapis.com/youtube/v3/channels?part=contentDetails&forUsername=majorleagueeating&key=AIzaSyAndVW5rvhiFgUsrVgjxONxhF6Bl35zzTc"
      )
        .then(resp => resp.json())
        .then(resp => {
          const pid = resp.items[0].contentDetails.relatedPlaylists.uploads
          return fetch(
            `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${pid}&maxResults=6&key=AIzaSyAndVW5rvhiFgUsrVgjxONxhF6Bl35zzTc`
          )
            .then(resp => resp.json())
            .then(resp => {
              return this.props.updateState("youtube", resp.items)
            })
        })
    }
    return null
  }
  render() {
    if (this.props.data.length === 0) {
      return <div />
    }
    const videos = this.renderVideos(this.props.data)
    return (
      <YouTubeContainer>
        <VideoContainer>
          <iframe
            id="ytplayer"
            title="YouTube Player"
            type="text/html"
            width="640"
            height="360"
            allow="autoplay;encrypted-media"
            src={`https://www.youtube.com/embed/${
              this.props.data[
                0
              ].snippet.resourceId.videoId
            }?cc_lang_pref=en&cc_load_policy=1&autoplay=1&mute=1&modestbranding=1&yt:cc=on`}
            frameborder="0"
            allowFullScreen
          />
          <iframe
            id="ytplayer-mobile"
            title="YouTube Player Mobile"
            type="text/html"
            width="320"
            height="180"
            allow="autoplay;encrypted-media"
            src={`https://www.youtube.com/embed/${
              this.props.data[
                Math.floor(Math.random() * this.props.data.length)
              ].snippet.resourceId.videoId
            }?cc_lang_pref=en&cc_load_policy=1&autoplay=1&mute=1&modestbranding=1&yt:cc=on`}
            frameborder="0"
            allowFullScreen
          />
          <div
            className="copyText"
            style={{ textAlign: "center", flexBasis: "50%" }}
          >
            <Header2>
              <em>The official YouTube of Competitive Eating</em>
            </Header2>
            <Header2>Subscribe for more!</Header2>
            <a
              href="https://www.youtube.com/channel/UClP_eBypwxmT3_3Qc5lN6Kg"
              target="_blank"
            >
              <img width="50%" src={youtubeImage} />
            </a>
          </div>
        </VideoContainer>
        <VideoList>{videos}</VideoList>
        <div
          className="copyTextMobile"
          style={{ textAlign: "center", flexBasis: "50%" }}
        >
          <Header2>Subscribe for more videos!</Header2>
          <a
            href="https://www.youtube.com/channel/UClP_eBypwxmT3_3Qc5lN6Kg"
            target="_blank"
          >
            <img width="50%" src={youtubeImage} />
          </a>
        </div>
        <ButtonContainer>
          <a
            href="https://www.youtube.com/channel/UClP_eBypwxmT3_3Qc5lN6Kg"
            target="_blank"
          >
            <PrimaryButton>View more</PrimaryButton>
          </a>
        </ButtonContainer>
      </YouTubeContainer>
    )
  }
}
