import React, { useState } from "react"
import styled from "@emotion/styled"
import { Subheading } from "../headings"
import { PrimaryButton } from "../buttons"
import FluidContainer from "../fluidContainer"
import background from "../../images/sub-background.png"

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

const SubscribeContainer = styled.div`
  padding: 1rem 2rem;
  margin: 0 auto;
  width: 70%;
  min-width: 260px;
  background-image: url(${background});
  font-family: roboto;
  background-color: #003557;
  background-position: center;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    margin-right: 1rem;
    height: 2.5rem;
    padding: 1rem;
    transform: skew(-25deg);
    flex: 1 0 50%;
    width: 100%;
  }
`

const Spacer = styled.div`
  padding: 2rem 0;
`

const Subscribe = () => {
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const onSubmit = e => {
    e.preventDefault()
    if (!email.match(emailRegex)) {
      return setError("Please provide a valid e-mail address")
    }
    const body = JSON.stringify({
      email,
    })
    return fetch(`${process.env.GATSBY_API_URL}/newsletters`, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    }).then(resp => {
      if (resp.ok) {
        setSubmitted(true)
      } else {
        setError("There was an error. Please try again later.")
      }
    })
  }
  return (
    <Spacer>
      <FluidContainer>
        <SubscribeContainer>
          <Subheading style={{ marginBottom: 0 }}>Subscribe now</Subheading>
          <p style={{ marginBottom: "1rem" }}>
            Join our mailing list to receive the latest updates and promotions
          </p>
          {!submitted && (
            <>
              <Form onSubmit={onSubmit}>
                <input
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email address"
                />
                <br />
                <br />
                <br />
                <PrimaryButton type="submit">Submit</PrimaryButton>
              </Form>
              {error && <small style={{ color: "red" }}>{error}</small>}
            </>
          )}
          {submitted && (
            <p>
              You're subscribed! Follow us on social media to further get
              involved!
            </p>
          )}
        </SubscribeContainer>
      </FluidContainer>
    </Spacer>
  )
}

export default Subscribe
