import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Subheading } from "../headings"
import placeholder from "../../images/logo.png"
import FluidContainer from "../fluidContainer"
import { PrimaryButton } from "../buttons"
import Sonya from "../../images/sonya.png"
import moment from "moment"

const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 25px;
  width: 40%;
  z-index: 2;
  @media (max-width: 768px) {
    display: none;
  }
`

const ButtonContainer = styled.div`
  text-align: center;
`

const SubheadingContainer = styled.div`
  text-align: center;
`

const EventList = styled.ul`
  list-style: none;
  margin-left: 20%;
  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const Event = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: roboto;
  font-size: 1rem;
  align-items: center;
  border-right: 8px #b5232c solid;
  height: 6rem;
  @media (max-width: 576px) {
    border-right: none;
  }

  & > * {
    height: 100%;
    display: flex;
    align-items: center;
  }
`
const Name = styled.div`
  margin-right: -2rem;
  padding-left: 15vw;
  z-index: 1;
  background-color: #1b2731;
  flex: 1 0 70%;
  padding-right: 1rem;
  transform: skew(-25deg);
  span {
    transform: skew(25deg);
  }
  @media (max-width: 768px) {
    padding-left: 2rem;
  }
  @media (max-width: 576px) {
    border-right: 8px #b5232c solid;
    transform: skew(0deg);
    padding-left: 0rem;
    margin-right: 0;
    span {
      transform: skew(0deg);
      padding-left: 2rem;
    }

    &::before {
      opacity: 0.1;
      background-image: url(${props => props.background});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      content: "";
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const Image = styled.div`
  width: 100%;
  margin: 0;
  flex-basis: 30%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;
  text-align: center;
  overflow: hidden;
  img {
    width: auto;
    margin: 0 auto;
    max-height: 100px;
    max-width: 150px;
    z-index: 1;
    position: relative;
    right: -1rem;
  }
  &::before {
    content: "";
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${props =>
      props.image ? `url(${props.image})` : `url(${placeholder})`};
    opacity: 0.2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 576px) {
    display: none;
  }
`

const Spacer = styled.div`
  padding: 2rem 0;
`

const createListItem = ({ id, date, name, logo, sn }) => (
  <li key={id}>
    <Link to={`/contests/${sn}`}>
      <Event>
        <Name background={logo && logo.image ? logo.image.url : placeholder}>
          <span>
            <strong>{name}</strong>
            <br />
            <em>{date}</em>
          </span>
        </Name>
        <Image image={logo && logo.image ? logo.image.url : placeholder}>
          <img src={logo && logo.image ? logo.image.url : placeholder} />
        </Image>
      </Event>
    </Link>
  </li>
)

const sortDataIntoLists = data => {
  const hasHappened = []
  const hasNotHappened = []

  data &&
    data.forEach(document => {
      const listItem = createListItem(document.node)
      const eventDate = document.node.date
      if (moment().isAfter(moment(eventDate), "day")) {
        return hasHappened.push(listItem)
      }
      return hasNotHappened.push(listItem)
    })

  return [hasHappened, hasNotHappened]
}

const Events = ({ documents }) => {
  const [hasHappened, hasNotHappened] = sortDataIntoLists(documents)
  const hasHappenedToShow = 5 - hasNotHappened.length
  return (
    <Spacer>
      <FluidContainer>
        {!hasNotHappened.length && (
          <>
            <SubheadingContainer>
              <Subheading>Upcoming Events</Subheading>
            </SubheadingContainer>
            <p style={{textAlign: "center"}}>Stay tuned for future events!</p>
          </>
        )}
        {!!hasNotHappened.length && (
          <>
            <SubheadingContainer>
              <Subheading>Upcoming Events</Subheading>
            </SubheadingContainer>
            <ImageContainer>
              <img src={Sonya} />
            </ImageContainer>
            <EventList>{hasNotHappened.reverse().slice(0, 5)}</EventList>
          </>
        )}
        {hasHappenedToShow && hasHappenedToShow > 0 && (
          <>
            <SubheadingContainer>
              <Subheading>Past Events</Subheading>
            </SubheadingContainer>
            <EventList>{hasHappened.slice(0, hasHappenedToShow)}</EventList>
          </>
        )}
        <ButtonContainer>
          <Link to="/contests">
            <PrimaryButton>View more</PrimaryButton>
          </Link>
        </ButtonContainer>
      </FluidContainer>
    </Spacer>
  )
}

export default Events
