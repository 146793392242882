import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Subheading } from "../headings"
import { PrimaryButton } from "../../components/buttons"

const ButtonContainer = styled.div`
  text-align: center;
`

const RecordContainer = styled.div`
  padding: 3rem 0;
  @media (max-width: 576px) {
    .no-mobile {
      display: none;
    }
  }
`

const SubheadingContainer = styled.div`
  text-align: center;
`

const RecordTable = styled.table`
  max-width: 1080px;
  font-family: roboto;
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  opacity: 0.8;
  margin-bottom: 3rem;

  td,
  th {
    padding: 0.2rem 1rem;
    font-size: 13px;
    text-align: left;
    border-top: 2px solid #434d54;
    border-bottom: 2px solid #black;
  }

  tr {
    background-color: rgba(100, 100, 100, 0.2);
    &:hover {
      filter: brightness(2);
      cursor: pointer;
    }
  }

  th {
    font-size: 16px;
  }

  tr:nth-child(1) {
    &:hover {
      filter: brightness(1);
      cursor: unset;
    }
  }

  tr:nth-child(even) {
    background-color: #27323b;
  }
`

const Records = ({ records }) => {
  return (
    <RecordContainer>
      <SubheadingContainer>
        <Subheading>Records</Subheading>
      </SubheadingContainer>
      <RecordTable>
        <tr>
          <th>Food</th>
          <th>Detail</th>
          <th class="no-mobile">Time</th>
          <th>Owner</th>
        </tr>
        {records.map(r => {
          const name = r.node.profile ? (
            <span>
              {r.node.profile.nameF} {r.node.profile.nameL}
            </span>
          ) : (
            "Unset"
          )
          return (
            <tr onClick={() => (window.location.pathname = "/records")}>
              <td>{r.node.food}</td>
              <td>{r.node.description}</td>
              <td class="no-mobile">{r.node.time}</td>
              <td style={{ color: "#f16263" }}>{name}</td>
            </tr>
          )
        })}
      </RecordTable>
      <ButtonContainer>
        <Link to="/records">
          <PrimaryButton>View more</PrimaryButton>
        </Link>
      </ButtonContainer>
    </RecordContainer>
  )
}
export default Records
