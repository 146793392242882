import React from "react"
import { PrimaryButton } from "../buttons"
import styled from "@emotion/styled"

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;
  @media (max-width: 576px) {
    flex-direction: column;
    flex-wrap: unset;
  }
  a {
    flex-basis: 31%;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
      flex-basis: 49%;
    }

    @media (max-width: 576px) {
      flex-basis: 100%;
    }
  }
`

const Img = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  height: 300px;
  width: 300px;
  background-size: cover;
  margin: 0.5rem;
  @media (max-width: 576px) {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  text-align: center;
`

const Caption = styled.div`
  position: absolute;
  bottom: 9px;
  left: 9px;
  background-color: #b5232c;
  text-decoration: none;
  color: white;
  font-family: roboto;
  padding: 0.5rem;
  font-weight: 800;
  width: 80%;
  opacity: 0.9;
  line-height: 1;
  font-size: 0.8rem;
  max-height: 100px;
  overflow: hidden;
  &::after {
    content: "...";
  }
`

export default class InstagramFeed extends React.Component {
  componentDidMount() {
    if (this.props.data.length === 0) {
      fetch(
        "https://api.instagram.com/v1/users/self/media/recent/?count=6&access_token=5353563939.057f26f.42180e8d08a84406990b1749aafa2e72"
      )
        .then(resp => resp.json())
        .then(resp => {
          return this.props.updateState("instagram", resp.data)
        })
    }
    return null
  }

  render() {
    return (
      <>
        <ImageContainer>
          {this.props.data.map(d => {
            return (
              <a target="_blank" href={d.link}>
                <Img src={d.images.low_resolution.url} />
                <Caption>{d.caption.text.substring(0, 100)}</Caption>
              </a>
            )
          })}
        </ImageContainer>
        <ButtonContainer>
          <a href="https://instagram.com/eatingcontest" target="_blank">
            <PrimaryButton>View more</PrimaryButton>
          </a>
        </ButtonContainer>
      </>
    )
  }
}
