import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import ContentContainer from "../contentContainer"
import FluidContainer from "../fluidContainer"
import Slider from "react-slick"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "./carousel.css"

const ImageContainer = styled.div`
  img {
    height: auto;
    margin-bottom: 0;
    padding: 0.3rem;
    max-width: 100%;
  }
`

export default class Carousel extends React.Component {
  getLogos = logos =>
    logos.map(l => (
      <ImageContainer>
        <Img fluid={l.node.image.childImageSharp.fluid} />
      </ImageContainer>
    ))
  render() {
    var settings = {
      dots: false,
      arrows: false,
      autoplay: true,
      lazyLoad: true,
      infinite: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    }
    return (
      <FluidContainer>
        <ContentContainer style={{ backgroundColor: "white", padding: "1rem" }}>
          <Slider {...settings}>
            {this.getLogos(this.props.logos.filter(l => l.node.image))}
          </Slider>
        </ContentContainer>
      </FluidContainer>
    )
  }
}
